<template>
  <div>
    <b-form-tags :value="selectedValue" @input="onTagAdded($event)" no-outer-focus class="mb-2">
      <template v-slot="{ tags, disabled, addTag, removeTag }">
        <ul class="list-inline d-inline-block mb-2" v-if="tags.length > 0">
          <li class="list-inline-item" v-for="tag in tags" :key="tag">
            <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled">{{ tag }}</b-form-tag>
          </li>
        </ul>

        <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100" :disabled="usersLoading">
          <template v-slot:button-content>
            <b-spinner small v-if="usersLoading" /> <UserIcon /> Select Users
          </template>
          <b-dropdown-form @submit.stop.prevent="() => {}">
            <b-form-group class="mb-0" label-for="user_search_input" label="Search users:" label-cols-md="auto" label-size="sm" :disabled="disabled">
              <b-form-input v-model="searchText" id="user_search_input" type="search" size="sm" autocomplete="off"></b-form-input>
            </b-form-group>
          </b-dropdown-form>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-group id="dropdown-group-1" class="users_scroll" v-if="availableUserNames.length">
            <b-dropdown-item-button v-for="user in availableUserNames" :key="user" @click="onOptionClick(user, addTag)">{{ user }}</b-dropdown-item-button>
          </b-dropdown-group>
          <b-dropdown-text v-else>
            There are no users to select
          </b-dropdown-text>
        </b-dropdown>
      </template>
    </b-form-tags>
  </div>
</template>

<script>
import UserIcon from 'mdi-vue/AccountMultipleCheck';
import { mapState } from 'vuex';

export default {
  name: 'UserPicker',
  components: { UserIcon },

  props: {
    value: { type: Array, default: () => [] },
  },

  data() {
    return {
      selectedValue: [],
      searchText: '',
      usersLoading: false,
    };
  },

  watch: {
    value: function valueChanged(newVal) {
      const valueNames = newVal.map(val => val.display_name);
      this.selectedValue = [...new Set(valueNames)];
    },
  },

  created() {
    this.getAllUsers();
  },

  computed: {
    ...mapState('user', ['users']),
    uniqueUsers() {
      const uniqueUsers = [];
      this.users.forEach((usr) => {
        if (uniqueUsers.filter(uniq => uniq.display_name === usr.display_name).length) return;
        uniqueUsers.push(usr);
      });
      return uniqueUsers;
    },
    availableUserNames() {
      // get all unique user names
      let userNames = this.uniqueUsers.map(usr => usr.display_name);
      // Filter out already selected users
      userNames = userNames.filter(usr => this.selectedValue.indexOf(usr) < 0);
      // Show only users that match search criteria
      const criteria = this.searchText.trim().toLowerCase();
      if (criteria) userNames = userNames.filter(usr => usr.toLowerCase().indexOf(criteria) >= 0);

      return userNames.sort();
    },
  },

  methods: {
    getAllUsers() {
      if (this.users && this.users.length) return;
      this.usersLoading = true;
      this.$store.dispatch('user/getAllUsers').catch(() => {}).finally(() => {
        this.usersLoading = false;
      });
    },
    onOptionClick(userName, addTag) {
      addTag(userName);
      this.searchText = '';
    },
    onTagAdded(evt) {
      let selectedUsers = [];
      if (evt.length) selectedUsers = this.uniqueUsers.filter(usr => evt.indexOf(usr.display_name) >= 0);
      this.$emit('input', selectedUsers);
    },
  },

};
</script>

<style lang="stylus" scoped>

.users_scroll
  ul
    margin-right 1rem
    max-height 15rem
    overflow hidden
    overflow-y scroll
    &::-webkit-scrollbar
      width 0.5rem
    &::-webkit-scrollbar-thumb
      background rgba(0, 0, 0, 0.6)
      border-radius 0.3rem

</style>
